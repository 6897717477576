/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

@media print {
  .overflow-y-scroll {
    overflow: visible!important;
  }
  .overflow-y-auto {
    overflow: visible!important;
  }
  .overflow-hidden {
    overflow: visible!important;
  }
  .headerPrint {
    display: none!important;
    opacity: 0!important;
    width: 1px!important;
  }
  nav {
    opacity: 0!important;
  }
}

@layer base {
  @font-face {
    font-family: "Diodrum";
    src: local("Diodrum Bold"), local("Diodrum - Bold"),
      url("/font/Diodrum/Diodrum - Bold.woff2") format("woff2"),
      url("/font/Diodrum/Diodrum - Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Diodrum";
    src: local("Diodrum Semibold"), local("Diodrum - Semibold"),
      url("/font/Diodrum/Diodrum - Semibold.woff2") format("woff2"),
      url("/font/Diodrum/Diodrum - Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Diodrum";
    src: local("Diodrum Medium"), local("Diodrum - Medium"),
      url("/font/Diodrum/Diodrum - Medium.woff2") format("woff2"),
      url("/font/Diodrum/Diodrum - Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Diodrum";
    src: local("Diodrum Regular"), local("Diodrum - Regular"),
      url("/font/Diodrum/Diodrum - Regular.woff2") format("woff2"),
      url("/font/Diodrum/Diodrum - Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Diodrum";
    src: local("Diodrum Light"), local("Diodrum - Light"),
      url("/font/Diodrum/Diodrum - Light.woff2") format("woff2"),
      url("/font/Diodrum/Diodrum - Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Diodrum";
    src: local("Diodrum Extralight"), local("Diodrum - Extralight"),
      url("/font/Diodrum/Diodrum - Extralight.woff2") format("woff2"),
      url("/font/Diodrum/Diodrum - Extralight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Diodrum", sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0067b9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 12px;
}

.slider.round:before {
  border-radius: 50%;
}

.amplify-tabs {
  display: none !important;
}
/* 
.amplify-button {
 background-color: #0067b9 !important;
}
.amplify-button:hover {
 background-color: #2196f3 !important;;
} */
